.header {
    padding-top: 40px;
    padding-bottom: 40px;

    $h: &;

    text-align: center;
    color: $white;

    &__logo {
        display: inline-block;
    }

    &__title {
        margin: 0;
        padding-top: 40px;
    }
}
