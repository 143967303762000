@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,600');

@import url('https://fonts.googleapis.com/icon?family=Material+Icons');


/** Proxima Nova **/

@font-face {
    font-family: 'Proxima Nova';
    src: url('/fonts/Mark Simonson - Proxima Nova Alt Black-webfont.eot');
    src: url('/fonts/Mark Simonson - Proxima Nova Alt Black-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/Mark Simonson - Proxima Nova Alt Black-webfont.woff2') format('woff2'),
         url('/fonts/Mark Simonson - Proxima Nova Alt Black-webfont.woff') format('woff'),
         url('/fonts/Mark Simonson - Proxima Nova Alt Black-webfont.ttf') format('truetype'),
         url('/fonts/Mark Simonson - Proxima Nova Alt Black-webfont.svg#proxima_nova_altblack') format('svg');
    font-weight: 900;
    font-style: normal;

}

@font-face {
    font-family: 'Proxima Nova';
    src: url('/fonts/Mark Simonson - Proxima Nova Alt Bold-webfont.eot');
    src: url('/fonts/Mark Simonson - Proxima Nova Alt Bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/Mark Simonson - Proxima Nova Alt Bold-webfont.woff2') format('woff2'),
         url('/fonts/Mark Simonson - Proxima Nova Alt Bold-webfont.woff') format('woff'),
         url('/fonts/Mark Simonson - Proxima Nova Alt Bold-webfont.ttf') format('truetype'),
         url('/fonts/Mark Simonson - Proxima Nova Alt Bold-webfont.svg#proxima_nova_altbold') format('svg');
    font-weight: 700;
    font-style: normal;

}

@font-face {
    font-family: 'Proxima Nova';
    src: url('/fonts/Mark Simonson - Proxima Nova Alt Regular-webfont.eot');
    src: url('/fonts/Mark Simonson - Proxima Nova Alt Regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/Mark Simonson - Proxima Nova Alt Regular-webfont.woff2') format('woff2'),
         url('/fonts/Mark Simonson - Proxima Nova Alt Regular-webfont.woff') format('woff'),
         url('/fonts/Mark Simonson - Proxima Nova Alt Regular-webfont.ttf') format('truetype'),
         url('/fonts/Mark Simonson - Proxima Nova Alt Regular-webfont.svg#proxima_nova_altregular') format('svg');
    font-weight: 500;
    font-style: normal;

}

@font-face {
    font-family: 'Proxima Nova';
    src: url('/fonts/Mark Simonson - Proxima Nova ScOsf Thin-webfont.eot');
    src: url('/fonts/Mark Simonson - Proxima Nova ScOsf Thin-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/Mark Simonson - Proxima Nova ScOsf Thin-webfont.woff2') format('woff2'),
         url('/fonts/Mark Simonson - Proxima Nova ScOsf Thin-webfont.woff') format('woff'),
         url('/fonts/Mark Simonson - Proxima Nova ScOsf Thin-webfont.ttf') format('truetype'),
         url('/fonts/Mark Simonson - Proxima Nova ScOsf Thin-webfont.svg#proxima_nova_scosfthin') format('svg');
    font-weight: 300;
    font-style: normal;

}

@font-face {
    font-family: 'Proxima Nova';
    src: url('/fonts/Mark Simonson - Proxima Nova Semibold-webfont.eot');
    src: url('/fonts/Mark Simonson - Proxima Nova Semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/Mark Simonson - Proxima Nova Semibold-webfont.woff2') format('woff2'),
         url('/fonts/Mark Simonson - Proxima Nova Semibold-webfont.woff') format('woff'),
         url('/fonts/Mark Simonson - Proxima Nova Semibold-webfont.ttf') format('truetype'),
         url('/fonts/Mark Simonson - Proxima Nova Semibold-webfont.svg#proxima_nova_ltsemibold') format('svg');
    font-weight: 600;
    font-style: normal;

}

@font-face {
    font-family: 'Proxima Nova';
    src: url('/fonts/Mark Simonson - Proxima Nova Thin-webfont.eot');
    src: url('/fonts/Mark Simonson - Proxima Nova Thin-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/Mark Simonson - Proxima Nova Thin-webfont.woff2') format('woff2'),
         url('/fonts/Mark Simonson - Proxima Nova Thin-webfont.woff') format('woff'),
         url('/fonts/Mark Simonson - Proxima Nova Thin-webfont.ttf') format('truetype'),
         url('/fonts/Mark Simonson - Proxima Nova Thin-webfont.svg#proxima_nova_ltthin') format('svg');
    font-weight: 200;
    font-style: normal;

}
