.home {
    &__container {
        background: $white;
        box-shadow: 0 0 15px 5px rgba(0,0,0,.4);
        position: static;
        margin-bottom: 10%;
        padding-top: 40px;
        padding-bottom: 20px;
        min-height: 500px;

        &:before {
            content: '';
            position: absolute;
            top:0;
            left:0;
            right:0;
            bottom: 20%;
            background: $mainColor;
            opacity: .8;
            display: block;
            z-index: -1;

            @include mq(sm){
                bottom: 40%;
            }
        }
    }

    &__step {
        display: none;

        &.-active {
            display: block;
        }

        &__tag {
            background: $yellow;
            color: $mainColor;
            text-align: center;
            padding: 15px 15px 0;
            position: absolute;
            right: 0;
            top: -70px;
            text-transform: uppercase;
            font-weight: 600;
            width: 200px;

            p {
                margin: 0;
            }

            span {
                color: $white;
                display: block;
            }

            &:after {
                content: '';
                position: absolute;
                top: 100%;
                border-left: 100px solid transparent;
                border-right: 100px solid transparent;
                border-top: 40px solid $yellow;
                left:0;
            }
        }

        &__intro {
            color: $white;
            max-width: 600px;
            margin: auto;
            padding: 40px 0;
            font-weight: 500;
            font-size: 26px;
            line-height: 1;
        }

        &__footer {
            display: flex;
            padding-top: 40px;

            span {
                display: block;
                height: 10px;

                &:not(:last-child) {
                    margin-right: 10px;
                }

                &:nth-child(1) {
                    background: $yellow;
                    flex-basis: 17%;
                }
                &:nth-child(2) {
                    background: $blueGray;
                    flex-basis: 17%;
                }
                &:nth-child(3) {
                    background: $mainColor;
                    flex-basis: 66%;
                }
            }
        }

        &__heading {

        }

        &__title {
            font-size: 32px;
            margin: 0 0 10px;

            strong {
                color: $mainColor;
                font-weight: 900;
            }
        }

        &__desc {
            text-transform: uppercase;
            margin: 0;
            font-size: 18px;
            padding-bottom: 40px;
        }

        &__body {
            min-height: 400px;
            &--step3 {
                .col-sm-8 {
                    height: 60px;
                }
            }
        }
    }

    &__step4 {
        &__title {
            color: $mainColor;
            font-size: 42px;
            font-weight: 900;
        }

        &__desc {
            font-size: 21px;
            text-transform: uppercase;
            color: $midGray;
            max-width: 500px;
            margin: auto;
            margin-bottom: 40px;
        }

        .form__step__prev {
            max-width: 150px;
            display: block;
            margin: auto;
        }
    }

    &__insurance {
        margin-bottom: 10px;

        &__heading {
            position: absolute;
            left: -9999px;

            &:checked {
                & + .home__insurance__name {
                    &:after {
                        opacity: 0;
                    }

                    & + .home__insurance__body {
                        max-height: 100%;
                        opacity: 1;
                        padding-top: 20px;
                        padding-bottom: 20px;

                        @include mq(sm) {
                            max-height: 50vh;
                        }
                    }
                }
            }
        }

        &__name {
            display: block;
            height: 30px;
            line-height: 30px;
            background: $mainColor;
            color: $white;
            padding: 0 15px;
            text-transform: uppercase;
            position: relative;

            &:before,
            &:after {
                @include trans;
                content: '';
                position: absolute;
                width: 12px;
                height: 2px;
                top: calc(50% - 1px);
                right: 15px;
                background: $white;
            }

            &:after {
                transform: rotate(90deg);
            }
        }

        &__body {
            @include trans;
            overflow: hidden;
            opacity: 0;
            max-height: 0;
            padding-top: 0;
            padding-bottom: 0;
        }

        &__items {
            display: flex;
            flex-direction: column;

            @include mq(md) {
                flex-direction: row;
            }

            &__logo {
                flex-basis: 25%;
                width: 25%;
                padding-right: 10px;

                @include mq(sm){
                    flex-basis: 8.33%;
                    width: 8.33%;
                }

                img {
                    width: 100%;
                }
            }

            &__choice {
                $c:&;

                padding: 0 15px;
                flex-basis: 22%;
                color: $midGray;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                padding-bottom: 15px;

                .form__step__next {
                    max-width: 150px;
                }

                @include mq(sm) {
                    &:not(:last-of-type) {
                        border-right: 1px solid $midGray;
                    }
                }


                &__price {
                    color: $mainColor;
                    font-size: 36px;
                    font-weight: 900;
                }

                &__desc {
                    height: 90px;
                    font-size: 21px;
                    line-height: 1;
                    padding-top: 30px;
                    margin: 0;
                }

                &--top {
                    padding: 15px;
                    border-right: none!important;
                    background: $lightestGray;
                    flex-grow: 1;

                    #{$c}__price {
                        color: $mainColor;
                        font-size: 54px;
                        font-weight: 900;
                    }

                    &__title {
                        color: $mainColor;
                        font-size: 24px;
                        font-weight: 900;
                        margin: 0;
                    }

                    #{$c}__desc {
                        height: auto;
                    }
                }
            }
        }
    }
}
.form {
    &__group {
        display: flex;
        height: 50px;
        align-items: center;

        &--right {
            justify-content: flex-end;

            a,
            button {
                margin-left: 15px;
            }
        }

        &--checkbox {
            flex-grow: 1;
        }

        &--payment {
            flex-wrap: wrap;
            height: auto;

            @include mq(sm){
                flex-wrap: nowrap;
                height: 50px;
            }

            span, div{
                margin-right: 15px;
            }
        }

        &--date,
        &--address {
            justify-content: space-between;
            input,
            select {
                &:nth-child(1),
                &:nth-child(3) {
                    flex-basis: 24%;
                }
                &:nth-child(2) {
                    flex-basis: 48%;
                }
            }
        }
    }

    &__input {
        width: 100%;
        border: none;
        border-bottom: 1px solid $lightGray;
        height: 40px;
        line-height: 40px;
        color: $midGray;
        text-transform: uppercase;

        &--amount {
            width: 50px;
            position: relative;
        }

        &--checkbox,
        &--radio {
            position: absolute;
            left:-9999px;

            &:checked {
                & + .form__label {
                    &:after {
                        opacity: 1;
                        transform: scale3d(1,1,1);
                    }
                }
            }

            & + .form__label {
                position: relative;
                padding-left: 30px;

                &:before {
                    content: '';
                    width: 20px;
                    height: 20px;
                    border: 1px solid $lightGray;
                    display: block;
                    position: absolute;
                    left:0;
                    top: 0;
                }

                &:after {
                    @include trans;
                    content: '';
                    width: 12px;
                    height: 12px;
                    background: $mainColor;
                    display: block;
                    position: absolute;
                    left:4px;
                    top: 4px;
                    opacity: 0;
                    transform: scale3d(0,0,0);
                }
            }
        }

        &--radio {

            & + .form__label {

                &:before {
                    border-radius: 50%;
                }

                &:after {
                    border-radius: 50%;
                }
            }
        }
    }

    &__label {
        font-size: 14px;
        font-weight: 700;
        color: $mainColor;
        text-transform: uppercase;

        &--alone {
            padding-top: 25px;
        }

        &--date {
            @include mq(sm){
                display: none;
            }
        }
    }

    &__step__next,
    &__step__prev {
        height: 40px;
        border: none;
        padding: 0 30px;
        line-height: 40px;
        color: $white;
        display: block;
        text-align: center;
        text-transform: uppercase;
    }

    &__step__next {
        background: $yellow;

        &:hover {
            background: $yellow - #333;
            color: $white;
        }
    }

    &__step__prev {
        background: $midGray;

        &:hover {
            background: $midGray - #333;
            color: $white;
        }
    }
}
.login {
    &__container {
        background: $white;
        box-shadow: 0 0 15px 5px rgba(0,0,0,.4);
        position: static;
        margin-bottom: 10%;
        padding-top: 50%;
        padding-bottom: 20px;
        min-height: 550px;
        max-width: 600px;

        @include mq(sm){
            padding-top: 9%;
        }

        &:before {
            content: '';
            position: absolute;
            top:0;
            left:0;
            right:0;
            bottom: 60%;
            background: $mainColor;
            opacity: .8;
            display: block;
            z-index: -1;
        }
    }
    & + .footer {
        .footer__char {

            &--left {
                left: 4%;
            }

            &--right {
                right: 11%;
            }
        }
    }

    .form__group {
        height: 70px;
    }

    .form__input {
        font-size: 21px;
        height: 50px;
    }

    &__heading {
        text-align: center;
        padding-top: 20%;
        margin-bottom: -40%;

        @include mq(sm) {
            padding-top: 4%;
            margin-bottom: -8%;
        }
    }

    .form__step__next {
        width: 200px;
        height: 50px;

        @include mq(sm) {
            position: absolute;
            bottom: -85px;
            left:0;
            right: 0;
            margin: auto;
        }
    }

    &__title {
        text-align: center;
        color: $darkGray;
        margin: 0 0 40px;
        @include mq(sm){
            font-size: 58px;
        }
    }
}
.user {
    &__container {
        background: $white;
        box-shadow: 0 0 15px 5px rgba(0,0,0,.4);
        position: static;
        margin-bottom: 10%;
        padding-top: 40px;
        padding-bottom: 20px;
        min-height: 500px;

        &:before {
            content: '';
            position: absolute;
            top:0;
            left:0;
            right:0;
            bottom: 40%;
            background: $mainColor;
            opacity: .8;
            display: block;
            z-index: -1;
        }
    }

    .form__group {
        flex-wrap: wrap;
    }

    &__title {
        color: $mainColor;
    }

    &__desc {
        text-transform: uppercase;
        margin: 0;
        font-size: 18px;
        padding-bottom: 40px;
    }

    &__label,
    &__value {
        text-transform: uppercase;
        color: $midGray;
        flex-basis: 100%;
        margin: 0;
        line-height: 1;
    }

    &__label {
        color: $lightGray;
    }

    &__spacer {
        height: 40px;
        width: 100%;
    }
}
