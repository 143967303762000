.footer {
    background: #1a252f;
    text-align: center;
    color: $white;
    pointer-events: none;

    .container {
        position: relative;
    }

    &__slogan {
        font-size: 21px;
        margin: 0 auto 10px;
        width: 80%;

        @include mq(sm){
            width: auto;
            margin: 0 0 10px;
        }

        strong {
            font-weight: 900;
        }
    }

    &__wrapper {
        min-height: 100px;
        padding: 10px 0;

        @include mq(sm) {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    &__char {
        position: absolute;
        bottom: 100%;

        &--left {
            left: -7%;
            width: 30%;

            @include mq(sm){

            }
        }

        &--right {
            right:0;
            width: 21%;

            @include mq(sm){
                right: -9%;
            }
        }
    }
}
